import React from 'react';

import Benefits from 'components/pages/data-science/benefits/benefits';
import Hero from 'components/pages/data-science/hero';
import MainLayout from 'layouts/main';
import SeoMetadata from 'utils/seo-metadata';

const DataScience = () => (
  <MainLayout pageMetadata={SeoMetadata['data-science']}>
    <Hero />
    <Benefits />
  </MainLayout>
);

export default DataScience;
