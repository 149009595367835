import React from 'react';

import CardWithNumber from 'components/shared/card-with-number';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

const title = 'Benefits of Yobi’s Platform';
const items = [
  {
    title: 'Expanded Feature Set',
    description:
      'Create a full profile on customer behavior by integrating Yobi’s privacy preserving signals to drive modeling and analysis',
  },
  {
    title: 'Pre-Processed and Structured Data',
    description:
      'Reduce excess time spent on the maintenance and hygiene of third party data. Yobi’s team of data scientists clean and test all data for quality to guarantee faster model deployment',
  },
  {
    title: 'Transfer Data Across the Cloud',
    description:
      'Export Yobi Data to any cloud provider, warehouse, or digital storage infrastructure. Yobi Data can be exported in your file type of choice',
  },
  {
    title: 'Predictive Representations of Behavior',
    description:
      'Yobi’s Behavioral Genome is explicitly designed to retain the predictive power of the raw behavioral signals on which they’re based. This is achieved by combining scientific insights from modern cognitive and behavioral science with predictive methods from industrial ML.',
  },
  {
    title: 'Privacy Preserving',
    description:
      'Yobi’s Behavioral Genome retains the predictive power of raw behavioral signals, but are not human readable, allowing data scientists to use this data for prediction without revealing the attributes of any individual customer',
  },
];

const firstRowElements = items.slice(0, 3);
const twoRowElements = items.slice(-2);

const Benefits = () => (
  <section className="relative pt-20 md:pt-32 lg:pt-40 pb-40 -mb-44 gradient-background-gray after:absolute after:-bottom-96 after:gradient-background-gray after:w-full after:h-96">
    <Container>
      <Heading className="text-center" tag="h2" size="lg" theme="black">
        {title}
      </Heading>
      <div className="grid lg:grid-cols-3 gap-8 mt-8 md:mt-16">
        {firstRowElements.map(({ title, description }, index) => (
          <CardWithNumber title={title} description={description} index={index} key={index} />
        ))}
      </div>
      <div className="grid lg:grid-cols-2 gap-8 mt-8">
        {twoRowElements.map(({ title, description }, index) => {
          const cardNumber = firstRowElements.length + index;
          return (
            <CardWithNumber
              title={title}
              description={description}
              index={cardNumber}
              key={cardNumber}
            />
          );
        })}
      </div>
    </Container>
  </section>
);

export default Benefits;
